export let metaCoinName = [
	'0x',
	'Aave',
	'Algorand',
	'Amp',
	'Avalanche',
	'Axie Infinity',
	'BAT',
	'BCH',
	'BSV',
	'BUSD',
	'Bancor',
	'Binance',
	'BitTorrent',
	'Bitcoin',
	'Bitcoin BEP2',
	'Bitcoin Gold',
	'Cardano',
	'Celo',
	'Celsius',
	'Chiliz',
	'Compound',
	'Cosmos',
	'Crypto dot com',
	'Dai',
	'Dash',
	'Decentraland',
	'Decred',
	'DigiByte',
	'Dogecoin',
	'EOS',
	'Elrond',
	'Enjin Coin',
	'Ethereum',
	'Ethereum Classic',
	'FTX',
	'Fantom',
	'Filecoin',
	'Flow',
	'Harmony',
	'Hedera Hashgraph',
	'Helium',
	'Holo',
	'Horizen',
	'Huobi Token',
	'ICON',
	'ICP',
	'IOTA',
	'Klaytn',
	'KuCoin Token',
	'Kusama',
	'Link',
	'Litecoin',
	'Maker',
	'Mdex',
	'Monero',
	'NEAR',
	'NEM',
	'Nano',
	'Neo',
	'Nexo',
	'OKB',
	'Ontology',
	'Origin',
	'PancakeSwap',
	'Paxos Standard',
	'Polkadot',
	'Polygon',
	'Pundi X',
	'Qtum',
	'Quant',
	'Revain',
	'SHIBA INU',
	'Siacoin',
	'Solana',
	'Stacks',
	'Stellar',
	'SushiSwap',
	'SwissBorg',
	'Synthetix',
	'THETA',
	'THORChain',
	'TRON',
	'Telcoin',
	'Terra',
	'TerraUSD',
	'Tether',
	'Tezos',
	'The Graph',
	'Theta Fuel',
	'TrueUSD',
	'UNUS SED LEO',
	'USDC',
	'Uniswap',
	'VeChain',
	'WBTC',
	'Waves',
	'XRP',
	'XinFin Network',
	'Yearn',
	'Zcash',
	'Zilliqa',
];

export let metaEyeColors = ['blue', 'brown', 'green', 'ishokushou', 'orange', 'pastel blue', 'pastel green', 'pastel purple', 'peach', 'pink', 'purple', 'red', 'yellow'];

export let metaHairColors = [
	'balayage',
	'black',
	'blonde',
	'blue',
	'brown',
	'green',
	'mixed',
	'orange',
	'pastel blue',
	'pastel green',
	'pastel purple',
	'peach',
	'pink',
	'purple',
	'red',
	'silver',
	'streaks',
	'white',
];

export let metaSkinColors = ['dark', 'elemental', 'metalic', 'pale', 'plastic', 'tanned'];

export let metaCostumes = [
	'android',
	'astronaut',
	'casual',
	'cyborg',
	'dancer',
	'elemental',
	'foodie',
	'formal',
	'furry',
	'futuristic',
	'mascot',
	'medieval',
	'military',
	'modern',
	'mystic',
	'performance',
	'sporty',
	'traditional',
	'trendy',
	'tribal',
	'uniform',
];

export let metaMainclass = ['Warrior', 'Mage', 'Rogue'];

export let metaSubclass = ['Paladin', 'Knight', 'Dark Knight', 'Dragoon', 'Sorcerer', 'Summoner', 'Cleric', 'Druid', 'Ranger', 'Berserker', 'Assassin', 'Monk'];

export let metaPetName = [
	'Blossoms',
	'Chill',
	'Gnarly',
	'Eco',
	'Yolk',
	'BonBon',
	'Nebula',
	'Blog',
	'Phlegm',
	'Chipper',
	'Dolores',
	'Ignite',
	'Mauve',
	'Tabby',
	'Yam',
	'Globs',
	'Jeebill',
	'Jovie',
	'Griff',
	'Momster',
	'Serene',
	'Rambam',
	'Gobbles',
	'Doughy',
	'Puffer',
	'Toadster',
	'Spotso',
	'Straps',
	'Fumes',
	'Hornbelle',
	'Hushplush',
	'Hypno',
	'The Egg',
	'The Doge',
	'Turtle',
	'Manta Ray',
	'Black Dragon',
	'Meerkat',
	'Ghost',
	'Salmon and Rice',
	'Green Phenix',
	'Axol',
	'The Balloon',
	'Drone of Neo',
	'Droid of Qtum',
	'Butterflies',
	'The Chameleon',
];
