const ReviveWings = () => (
	<svg xmlns="http://www.w3.org/2000/svg" fill="gold" className="h-24 w-24 mt-2" viewBox="0 0 442.936 442.936">
		<path
			d="M442.771,208.364c-0.395-1.652-1.471-3.059-2.962-3.872c-0.588-0.32-59.099-32.222-87.817-46.891
	c-27.778-14.188-47.267-6.024-56.048-0.292c-7.204-7.855-16.579-13.4-27.091-15.494c-17.783-3.541-34.988,3.542-47.385,19.107
	c-12.395-15.566-29.597-22.652-47.385-19.107c-10.512,2.094-19.887,7.641-27.091,15.494c-8.777-5.731-28.261-13.9-56.048,0.292
	c-28.719,14.669-87.229,46.57-87.817,46.891c-1.491,0.814-2.567,2.221-2.963,3.872c-0.395,1.651-0.071,3.394,0.891,4.793
	c6.283,9.145,21.034,22.427,39.93,22.425c2.133,0,4.321-0.179,6.555-0.543c2.938,3.88,8.516,9.488,17.684,12.475
	c4.226,1.377,8.733,2.064,13.515,2.064c3.707,0,7.58-0.419,11.608-1.244c2.782,3.145,7.729,7.674,15.138,10.716
	c4.421,1.815,10.111,3.239,17.082,3.239c8.96,0,20.045-2.374,33.256-9.272c13.008,15.407,32.824,31.941,62.755,48.268
	c0.912,0.497,1.896,0.733,2.868,0.733l0.024-0.096l0.024,0.096c0.972,0,1.956-0.236,2.868-0.733
	c29.928-16.326,49.744-32.858,62.751-48.266c13.215,6.901,24.296,9.27,33.258,9.27c6.97,0,12.662-1.425,17.082-3.239
	c7.396-3.037,12.348-7.566,15.138-10.717c4.028,0.826,7.902,1.245,11.61,1.245c4.78,0,9.29-0.688,13.515-2.064
	c9.164-2.984,14.74-8.59,17.68-12.469c2.233,0.364,4.423,0.543,6.558,0.543c18.888-0.001,33.646-13.285,39.929-22.431
	C442.843,211.758,443.166,210.015,442.771,208.364z M103.615,244.341c4.602-1.789,9.368-4.026,14.301-6.732
	c2.905-1.595,3.969-5.241,2.375-8.146c-1.594-2.906-5.244-3.968-8.146-2.375c-17.124,9.396-31.967,12.543-42.928,9.105
	c-4.057-1.273-7.041-3.262-9.164-5.167c0.025-0.013,0.052-0.022,0.078-0.035l35.293-17.277c2.977-1.457,4.208-5.051,2.751-8.026
	c-1.457-2.977-5.05-4.211-8.026-2.751l-35.271,17.266c-18.303,8.865-32.676-1.711-39.542-8.689
	c16.065-8.729,58.149-31.518,81.068-43.223c21.997-11.237,36.92-5.384,43.494-1.288c-3.482,6.03-5.92,12.83-7.03,20.107
	c-1.649,10.807-1.466,31.576,15.53,56.225C124.999,254.917,110.794,249.637,103.615,244.341z M221.469,289.169
	c-76.468-42.686-79.183-84.235-76.739-100.252c2.732-17.902,15.47-32.102,31.697-35.332c15.447-3.077,29.981,4.547,39.891,20.91
	l0.029-0.018c0.486,0.811,1.169,1.518,2.033,2.042c2.838,1.715,6.524,0.81,8.24-2.024c9.91-16.363,24.449-23.979,39.89-20.911
	c16.228,3.231,28.966,17.431,31.697,35.333C300.652,204.933,297.937,246.482,221.469,289.169z M388.081,220.213l-35.293-17.277
	c-2.977-1.458-6.57-0.225-8.026,2.751c-1.457,2.976-0.226,6.569,2.751,8.026l35.315,17.289c0.018,0.009,0.035,0.016,0.053,0.023
	c-2.075,1.861-4.976,3.806-8.883,5.078c-10.986,3.578-25.928,0.463-43.204-9.017c-2.902-1.593-6.553-0.53-8.147,2.375
	c-1.594,2.905-0.53,6.552,2.375,8.146c4.921,2.699,9.676,4.933,14.268,6.72c-1.729,1.283-3.836,2.569-6.395,3.62
	c-10.443,4.286-23.61,2.652-38.346-4.631c16.986-24.643,17.169-45.405,15.521-56.21c-1.11-7.274-3.546-14.071-7.026-20.101
	c6.556-4.064,21.519-9.939,43.488,1.282c22.919,11.705,65.003,34.494,81.068,43.223C420.731,218.496,406.365,229.06,388.081,220.213
	z"
		/>
	</svg>
);

export default ReviveWings;
