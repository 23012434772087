const SVGRevive = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 0C2.23859 0 0 2.23853 0 5V19C0 21.7615 2.23859 24 5 24H19C21.7614 24 24 21.7615 24 19V5C24 2.23853 21.7614 0 19 0H5ZM6.23471 8.31091C6.87009 7.47778 7.78857 7 8.75433 7C9.57611 7.005 10.3647 7.34509 10.9568 7.94971L11.9981 8.99524L13.0397 7.94958C13.6318 7.34497 14.4202 7.00513 15.2418 7.00012C16.2076 7.00012 17.126 7.47778 17.7615 8.31079C18.3535 9.09656 18.6455 10.0897 18.5797 11.0934C18.5203 12.118 18.1178 13.0531 17.4467 13.7268L12.3383 18.8538C12.2472 18.9475 12.1252 19 11.9983 19C11.8713 19 11.7493 18.9475 11.6582 18.8538L6.54959 13.7268C5.16095 12.3331 5.01965 9.90356 6.23471 8.31091ZM1 10.8971C1 9.67969 2.26404 8.69275 3.82327 8.69275C4.24057 8.69092 4.65509 8.76465 5.04904 8.91077C4.32303 10.7095 4.68015 12.9189 6.04694 14.2906L8.62299 16.8759C7.26895 17.55 5.81873 17.2721 5.33475 16.2209C5.24396 16.0223 5.19122 15.8065 5.17957 15.5862C3.87112 15.6737 2.74866 15.0115 2.55386 13.9611C2.49167 13.6062 2.53394 13.2395 2.67493 12.9109C1.68814 12.5674 1 11.7953 1 10.8971ZM18.9496 8.91101C19.3439 8.76465 19.7589 8.6908 20.1767 8.69275C21.736 8.69275 23 9.67957 23 10.8971C23 11.7953 22.3119 12.5673 21.3251 12.9109C21.4661 13.2394 21.5084 13.6061 21.4461 13.9611C21.2513 15.0114 20.1289 15.6735 18.8204 15.5862C18.8087 15.8065 18.756 16.0222 18.6653 16.2208C18.1809 17.2726 16.7292 17.5507 15.3744 16.8745L17.9493 14.2904C18.7556 13.4812 19.2388 12.3627 19.3098 11.1409C19.3563 10.3779 19.2329 9.61401 18.9496 8.91101Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default SVGRevive;
