const ChainLinkSVG = () => {
	return (
		<svg width="14" height="16" viewBox="0 0 137 158" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M68.5 0L54.0026 8.33486L14.4974 31.1651L0 39.5V118.5L14.4974 126.835L54.3651 149.665L68.8624 158L83.3598 149.665L122.503 126.835L137 118.5V39.5L122.503 31.1651L82.9974 8.33486L68.5 0ZM28.9947 101.83V56.1697L68.5 33.3395L108.005 56.1697V101.83L68.5 124.661L28.9947 101.83Z"
				fill="#2A5ADA"
			/>
		</svg>
	);
};

export default ChainLinkSVG;
