const SVGUnstake = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.7803 12.7554L16.3053 15.2304C16.159 15.3767 15.967 15.4502 15.775 15.4502C15.583 15.4502 15.391 15.3767 15.2448 15.2304C14.9515 14.9372 14.9515 14.4632 15.2448 14.1699L16.4395 12.9752H10.75C10.3353 12.9752 10 12.6399 10 12.2252C10 11.8104 10.3353 11.4752 10.75 11.4752H16.4395L15.2448 10.2804C14.9515 9.98719 14.9515 9.51319 15.2448 9.21994C15.538 8.92669 16.012 8.92669 16.3053 9.21994L18.7803 11.6949C19.0735 11.9882 19.0735 12.4622 18.7803 12.7554Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 0C2.23859 0 0 2.23853 0 5V19C0 21.7615 2.23859 24 5 24H19C21.7614 24 24 21.7615 24 19V5C24 2.23853 21.7614 0 19 0H5ZM9 5C7.89542 5 7 5.89539 7 7V17C7 18.1046 7.89542 19 9 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89539 20.1046 5 19 5H9Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default SVGUnstake;
