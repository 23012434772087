const CoinGeckoSVG = () => {
	return (
		<svg width="16" height="16" viewBox="0 0 158 158" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M158 78.6626C158.099 110.625 139.07 139.501 109.579 151.848C80.0875 164.195 46.1131 157.624 23.3971 135.12C0.681027 112.617 -6.19357 78.6626 5.86188 49.0895C17.9173 19.5165 46.7109 0.199356 78.5931 0.000211034C122.331 -0.0993616 157.8 35.0498 158 78.6626Z"
				fill="#8DC63F"
			/>
			<path
				d="M152.121 78.6626C152.221 108.236 134.586 134.921 107.287 146.372C79.9879 157.823 48.6039 151.649 27.5816 130.938C6.55933 110.128 0.182894 78.7622 11.3417 51.4793C22.5004 24.0968 49.1021 6.2733 78.5931 6.07415C119.044 5.87501 151.922 38.3357 152.121 78.6626Z"
				fill="#F9E988"
			/>
			<path
				d="M80.3865 10.7541C85.0692 9.95747 89.7519 9.95747 94.4346 10.7541C99.1172 11.4511 103.7 13.0442 107.885 15.2348C112.069 17.525 115.656 20.6117 119.243 23.4993C122.83 26.387 126.416 29.3741 129.804 32.66C133.291 35.8464 136.28 39.5305 138.771 43.5134C141.361 47.4964 143.453 51.778 145.048 56.1592C148.136 65.1207 149.132 74.7793 147.638 83.9399H147.14C145.645 74.7793 143.553 66.1164 140.265 57.8519C138.771 53.6698 136.878 49.5874 134.885 45.704C132.693 41.8207 130.402 37.9374 127.811 34.3528C125.221 30.6686 121.933 27.3827 118.247 24.6942C114.56 22.0058 110.276 20.313 106.191 18.7199C102.106 17.1267 97.9217 15.434 93.6375 14.1395C89.3533 12.9447 84.9695 11.9489 80.3865 11.0528V10.7541Z"
				fill="white"
			/>
			<path
				d="M116.055 52.8733C110.774 51.3797 105.294 49.1891 99.715 46.9985C99.4161 45.6045 98.1209 43.9117 95.6301 41.7211C92.0434 38.5348 85.1688 38.6344 79.2905 40.0284C72.8144 38.5348 66.3384 37.9374 60.1612 39.431C9.64788 53.3711 38.2422 87.2258 19.7107 121.379C22.3011 126.955 50.7958 159.615 91.9437 150.853C91.9437 150.853 77.8957 117.098 109.678 100.867C135.483 87.7237 154.014 63.2288 116.055 52.8733Z"
				fill="#8BC53F"
			/>
			<path d="M79.2905 40.0284C82.9769 40.3271 96.3276 44.6088 99.715 46.9985C96.8257 38.734 87.2611 37.6387 79.2905 40.0284Z" fill="#009345" />
			<path
				d="M122.331 75.078C122.331 77.7664 119.044 79.1604 117.151 77.2686C115.258 75.3767 116.553 72.0908 119.243 71.9912C120.937 72.0908 122.331 73.3852 122.331 75.078ZM82.7777 61.0382C82.7777 66.7139 79.2906 71.8916 74.0101 74.0822C68.7296 76.2728 62.652 75.078 58.5671 70.9955C54.4822 66.913 53.2867 60.8391 55.4786 55.5618C57.6705 50.2844 62.8513 46.7993 68.5303 46.7993C72.3163 46.7993 75.9031 48.2929 78.4935 50.8818C81.2832 53.6699 82.7777 57.2545 82.7777 61.0382Z"
				fill="white"
			/>
			<path
				d="M78.5931 61.1378C78.5931 65.1207 76.2019 68.8049 72.4159 70.2985C68.7295 71.7921 64.4454 70.9955 61.5561 68.1079C58.6667 65.2203 57.8697 61.0382 59.3642 57.2545C60.8586 53.5703 64.545 51.081 68.5303 51.081C74.1097 51.1806 78.5931 55.6613 78.5931 61.1378Z"
				fill="#58595B"
			/>
			<path
				d="M133.789 81.351C122.331 89.4164 109.28 95.4903 90.8478 95.4903C82.1799 95.4903 80.4861 86.3297 74.7075 90.8104C71.7185 93.1006 61.3568 98.2784 53.0874 97.9797C44.818 97.5814 31.4673 92.7023 27.7809 75.1775C26.2864 92.8019 25.589 105.746 18.9137 120.583C32.0651 141.692 63.4491 157.922 91.9438 150.952C88.8552 129.544 107.586 108.634 118.047 97.9797C122.132 93.7976 129.804 87.2258 133.789 81.351Z"
				fill="#8BC53F"
			/>
			<path
				d="M133.291 81.8489C129.704 85.1348 125.52 87.5245 121.235 89.6156C116.852 91.7066 112.368 93.2997 107.686 94.5942C103.003 95.7891 98.1209 96.6852 93.2389 96.2869C88.2574 95.8886 83.2758 94.1959 79.9879 90.5117L80.1872 90.3126C84.1724 92.9015 88.8551 93.7976 93.4382 93.9967C98.1209 94.0963 102.804 93.7976 107.387 92.9015C111.97 91.9057 116.553 90.5117 120.937 88.7194C125.32 86.9271 129.605 84.8361 133.391 81.8489H133.291Z"
				fill="#58595B"
			/>
		</svg>
	);
};

export default CoinGeckoSVG;
